

// -------- Production extension index ---------
//      ! THIS FILE WAS AUTO-GENERATED !
//
// Do not commit this file, the final index is
// compiled by the repo owner, use index_dev.js to
// experiment: 'npm run compile'

import Main from './main'
import Splitter from './Splitter.vue'
Main.__name__ = 'grid-resize'

const widgets = { Splitter }
const components = {  }
const overlays = {  }
const colorpacks = {  }
const skins = {  }

const Pack = {
    widgets,
    Splitter,
    components,
    overlays,
    colorpacks,
    skins,
    Main
}

export default Pack

export {
    widgets,
    Splitter,
    components,
    overlays,
    colorpacks,
    skins,
    Main
}