<template>
  <svg  viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M3.75 3.125C3.40482 3.125 3.125 3.40482 3.125 3.75V16.25C3.125 16.5952 3.40482 16.875 3.75 16.875H16.25C16.5952 16.875 16.875 16.5952 16.875 16.25V3.75C16.875 3.40482 16.5952 3.125 16.25 3.125H3.75ZM1.875 3.75C1.875 2.71447 2.71447 1.875 3.75 1.875H16.25C17.2855 1.875 18.125 2.71447 18.125 3.75V16.25C18.125 17.2855 17.2855 18.125 16.25 18.125H3.75C2.71447 18.125 1.875 17.2855 1.875 16.25V3.75Z"
      :fill="color" />
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M6.04167 6.66666C6.04167 6.32148 6.32149 6.04166 6.66667 6.04166H13.3333C13.6785 6.04166 13.9583 6.32148 13.9583 6.66666V7.91666C13.9583 8.26183 13.6785 8.54166 13.3333 8.54166C12.9882 8.54166 12.7083 8.26183 12.7083 7.91666V7.29166H10.5894C10.6125 7.35682 10.625 7.42694 10.625 7.49999V13.5417H10.8333C11.1785 13.5417 11.4583 13.8215 11.4583 14.1667C11.4583 14.5118 11.1785 14.7917 10.8333 14.7917H9.16667C8.82149 14.7917 8.54167 14.5118 8.54167 14.1667C8.54167 13.8215 8.82149 13.5417 9.16667 13.5417H9.375V7.49999C9.375 7.42694 9.38753 7.35682 9.41056 7.29166H7.29167V7.91666C7.29167 8.26183 7.01185 8.54166 6.66667 8.54166C6.32149 8.54166 6.04167 8.26183 6.04167 7.91666V6.66666Z"
      :fill="color" />
  </svg>


</template>
<script>
export default {
  name: "text_tool",
  props: {
    color: {
      type: String,
      default: "#000000"
    }
  }
}
</script>