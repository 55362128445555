<template>
  <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M2.70834 2.5C2.70834 2.15482 2.98816 1.875 3.33334 1.875L16.6667 1.875C17.0118 1.875 17.2917 2.15482 17.2917 2.5C17.2917 2.84518 17.0118 3.125 16.6667 3.125L3.33334 3.125C2.98816 3.125 2.70834 2.84518 2.70834 2.5Z"
      :fill="color" />
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M17.1086 2.05806C17.3527 2.30214 17.3527 2.69786 17.1086 2.94194L16.2753 3.77528C16.0312 4.01935 15.6355 4.01935 15.3914 3.77528C15.1473 3.5312 15.1473 3.13547 15.3914 2.89139L16.2247 2.05806C16.4688 1.81398 16.8645 1.81398 17.1086 2.05806ZM14.6086 4.55806C14.8527 4.80214 14.8527 5.19786 14.6086 5.44194L12.9419 7.10861C12.6979 7.35269 12.3021 7.35269 12.0581 7.10861C11.814 6.86453 11.814 6.4688 12.0581 6.22472L13.7247 4.55806C13.9688 4.31398 14.3645 4.31398 14.6086 4.55806ZM11.2753 7.89139C11.5194 8.13547 11.5194 8.5312 11.2753 8.77528L9.60861 10.4419C9.36453 10.686 8.96881 10.686 8.72473 10.4419C8.48065 10.1979 8.48065 9.80214 8.72473 9.55806L10.3914 7.89139C10.6355 7.64731 11.0312 7.64731 11.2753 7.89139ZM7.94194 11.2247C8.18602 11.4688 8.18602 11.8645 7.94194 12.1086L6.27528 13.7753C6.0312 14.0194 5.63547 14.0194 5.39139 13.7753C5.14732 13.5312 5.14732 13.1355 5.39139 12.8914L7.05806 11.2247C7.30214 10.9806 7.69787 10.9806 7.94194 11.2247ZM4.60861 14.5581C4.85269 14.8021 4.85269 15.1979 4.60861 15.4419L3.77528 16.2753C3.5312 16.5194 3.13547 16.5194 2.89139 16.2753C2.64732 16.0312 2.64732 15.6355 2.89139 15.3914L3.72473 14.5581C3.96881 14.314 4.36453 14.314 4.60861 14.5581Z"
      :fill="color" />
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M2.70834 15.8333C2.70834 15.4882 2.98816 15.2083 3.33334 15.2083L16.6667 15.2083C17.0118 15.2083 17.2917 15.4882 17.2917 15.8333C17.2917 16.1785 17.0118 16.4583 16.6667 16.4583L3.33334 16.4583C2.98816 16.4583 2.70834 16.1785 2.70834 15.8333Z"
      :fill="color" />
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M2.70834 9.16666C2.70834 8.82148 2.98816 8.54166 3.33334 8.54166L16.6667 8.54166C17.0118 8.54166 17.2917 8.82148 17.2917 9.16666C17.2917 9.51183 17.0118 9.79166 16.6667 9.79166L3.33334 9.79166C2.98816 9.79166 2.70834 9.51183 2.70834 9.16666Z"
      :fill="color" />
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M2.70834 13.3333C2.70834 12.9882 2.98816 12.7083 3.33334 12.7083L16.6667 12.7083C17.0118 12.7083 17.2917 12.9882 17.2917 13.3333C17.2917 13.6785 17.0118 13.9583 16.6667 13.9583L3.33334 13.9583C2.98816 13.9583 2.70834 13.6785 2.70834 13.3333Z"
      :fill="color" />
  </svg>


</template>
<script>
export default {
  name: "fib_retracement_tool",
  props: {
    color: {
      type: String,
      default: "#000000"
    }
  }
}
</script>