import { render, staticRenderFns } from "./HMA_SettingsWindow.vue?vue&type=template&id=018fcfc8&scoped=true"
import script from "./HMA_SettingsWindow.vue?vue&type=script&lang=js"
export * from "./HMA_SettingsWindow.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "018fcfc8",
  null
  
)

export default component.exports