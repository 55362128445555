export const layoutConfig = [
    {
        name: '1tab',
        className:'one-tab',
        imagePath: '/images/layout/layout_4tab.svg',
        darkImagePath: '/images/layout/layout_1tab_dark.svg',
        tabCount: 1
    },
    {
        name: '2tab_1',
        className:'two-tab-h',
        imagePath: '/images/layout/layout_2tab_1.svg',
        darkImagePath: '/images/layout/layout_2tab_1_dark.svg',
        tabCount: 2
    },
    {
        name: '2tab_2',
        className:'two-tab-v',
        imagePath: '/images/layout/layout_2tab_2.svg',
        darkImagePath: '/images/layout/layout_2tab_2_dark.svg',
        tabCount: 2
    },
    {
        name: '3tab_1',
        className:'three-tab-h',
        imagePath: '/images/layout/layout_3tab_1.svg',
        darkImagePath: '/images/layout/layout_3tab_1_dark.svg',
        tabCount: 3
    },
    {
        name: '3tab_2',
        className:'three-tab-v-h',
        imagePath: '/images/layout/layout_3tab_2.svg',
        darkImagePath: '/images/layout/layout_3tab_2_dark.svg',
        tabCount: 3
    },
    {
        name: '3tab_3',
        className:'three-tab-h-v',
        imagePath: '/images/layout/layout_3tab_3.svg',
        darkImagePath: '/images/layout/layout_3tab_3_dark.svg',
        tabCount: 3
    }, {
        name: '4tab',
        className:'four-tab',
        imagePath: '/images/layout/layout_4tab.svg',
        darkImagePath: '/images/layout/layout_4tab_dark.svg',
        tabCount: 4
    }

]