<template>
  <p-dialog :visible.sync="isOpen" header="Trailing Stop" @hide="onClose" :modal="true">
    <div>

      <div class="form-field">
        <label>Entry price</label>
        <p-inputText type="number" placeholder="Entry Price" step="0.01" min="0" v-model="entryPrice" />
      </div>

      <div class="form-field-drp p-d-flex p-ai-end">

        <div class="form-field" style="width: 50%;">
          <label>Trailing Type</label>
          <p-dropdown class="trailing-dropdown" v-model="selectedTrailingType" :options="trailingType"
            optionLabel="text" optionValue="value" appendTo='body' placeholder="Trailing Type" />
        </div>

        <div class="form-field" v-if="selectedTrailingType === 'step-price'">
          <label>Step Price</label>
          <p-inputText type="number" placeholder="Step Price" step="0.01" min="0" v-model="stepPrice"
            name="stepPrice" />
        </div>

        <div class="form-field" v-if="selectedTrailingType === 'value'">
          <label>Position Size</label>
          <p-inputText type="number" name="positionSize" placeholder="Position Size" value="1000" />
        </div>

        <div class="form-field" v-if="selectedTrailingType === 'value'">
          <h3>and</h3>
        </div>

        <div class="form-field" v-if="selectedTrailingType === 'value'">
          <label>Risk Value</label>
          <p-inputText type="number" placeholder="Risk Value" name="riskedValue" value="500" />
        </div>

        <div class="form-field" v-if="selectedTrailingType === '%value'">
          <label>Position Size</label>
          <p-inputText type="number" name="positionSize" placeholder="Position Size" value="1000" />
        </div>

        <div class="form-field" v-if="selectedTrailingType === '%value'">
          <h3>and</h3>
        </div>

        <div class="form-field" v-if="selectedTrailingType === '%value'">
          <label>Risk %</label>
          <p-inputText type="number" placeholder="Risk Value %" name="riskedValue" value="10" />
        </div>

        <div class="form-field" v-if="selectedTrailingType === 'atr'">
          <label>Day Period</label>
          <p-inputText type="number" name="atrPeriod" placeholder="ATR Period" value="10" />
        </div>

        <div class="form-field" v-if="selectedTrailingType === 'atr'">
          <h3>and</h3>
        </div>

        <div class="form-field" v-if="selectedTrailingType === 'atr'">
          <label>Multiplier</label>
          <p-inputText type="number" placeholder="Multiplier" name="multiplier" value="5" />
        </div>

      </div>

      <div class="form-field" v-if="
        selectedTrailingType === 'atr' ||
        selectedTrailingType === 'step-price'
      ">
        <label>Stop Price</label>
        <p-inputText type="number" placeholder="Stop Price" step="0.01" min="0" v-model="stopPrice" />

      </div>
    </div>
    <template #footer>
      <p-button label="OK" @click="updateData" />
      <p-button label="Cancel" class="p-button-secondary" @click="onClose" />
    </template>
  </p-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { chartEvents } from "../../../chartEvents";

export default {
  name: "TrailingStop",
  components: {
  },
  props: {
    trialingStopData: {
      type: Object,
      default: () => { }
    }
  },
  methods: {
    onClose() {
      this.$emit("hideTrailingStop");
    },
    updateData() {
      let identity = "UpdateTrailingStopSettings" + this.trialingStopData?.$uuid;
      // let identity = "UpdateTrailingStopSettings";
      chartEvents.$emit(identity, {
        stepPrice: this.stepPrice,
        stopPrice: this.stopPrice,
        entryPrice: this.entryPrice,
        uuid: this.trialingStopData?.$uuid,
      });
      this.$emit("hideTrailingStop");

    },
  },
  data() {
    return {
      isOpen: false,
      entryPrice: 0,
      stopPrice: 0,
      stepPrice: 0,
      trailingType: [
        { text: "based on step price", value: "step-price" },
        { text: "$ value of a parcel", value: "value" },
        { text: "% value of a parcel", value: "%value" },
        { text: "ATR", value: "atr" },
      ],
      selectedTrailingType: "step-price",
    };
  },
  computed: {
    ...mapGetters(["themeDarkMode", "themeFontColor", "themeSecondaryColor", "themePrimaryColor"]),
  },
  created() {
    if (this.$props?.trialingStopData) {
      this.stepPrice = this.$props?.trialingStopData?.stepPrice
      this.entryPrice = this.$props?.trialingStopData?.entryPrice
      this.stopPrice = this.$props?.trialingStopData?.stopPrice
    }
  },
  mounted() {
    if (this.$props?.trialingStopData) {
      this.isOpen = true;
    }
  }
};
</script>

<style>

</style>
