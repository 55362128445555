<template>

  <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M3.75 3.125C3.40482 3.125 3.125 3.40482 3.125 3.75V16.25C3.125 16.5952 3.40482 16.875 3.75 16.875H16.25C16.5952 16.875 16.875 16.5952 16.875 16.25V3.75C16.875 3.40482 16.5952 3.125 16.25 3.125H3.75ZM1.875 3.75C1.875 2.71447 2.71447 1.875 3.75 1.875H16.25C17.2855 1.875 18.125 2.71447 18.125 3.75V16.25C18.125 17.2855 17.2855 18.125 16.25 18.125H3.75C2.71447 18.125 1.875 17.2855 1.875 16.25V3.75Z"
      :fill="color" />
    <path
      d="M11.1806 12.1528V8.26389C11.1806 7.80558 11.1806 7.57643 11.0382 7.43405C10.8958 7.29167 10.6666 7.29167 10.2083 7.29167C9.75002 7.29167 9.52087 7.29167 9.37849 7.43405C9.23611 7.57643 9.23611 7.80558 9.23611 8.26389V12.1528C9.23611 12.6111 9.23611 12.8402 9.37849 12.9826C9.52087 13.125 9.75002 13.125 10.2083 13.125C10.6666 13.125 10.8958 13.125 11.0382 12.9826C11.1806 12.8402 11.1806 12.6111 11.1806 12.1528Z"
      :stroke="color" stroke-linecap="round" stroke-linejoin="round" />
    <path
      d="M14.5833 8.75V7.77778C14.5833 7.31947 14.5833 7.09031 14.441 6.94794C14.2986 6.80556 14.0694 6.80556 13.6111 6.80556C13.1528 6.80556 12.9236 6.80556 12.7813 6.94794C12.6389 7.09031 12.6389 7.31947 12.6389 7.77778V8.75C12.6389 9.20831 12.6389 9.43747 12.7813 9.57984C12.9236 9.72222 13.1528 9.72222 13.6111 9.72222C14.0694 9.72222 14.2986 9.72222 14.441 9.57984C14.5833 9.43747 14.5833 9.20831 14.5833 8.75Z"
      :stroke="color" stroke-linecap="round" stroke-linejoin="round" />
    <path
      d="M7.77778 11.1806V10.2083C7.77778 9.75003 7.77778 9.52087 7.6354 9.37849C7.49302 9.23611 7.26386 9.23611 6.80555 9.23611C6.34724 9.23611 6.11809 9.23611 5.97571 9.37849C5.83333 9.52087 5.83333 9.75003 5.83333 10.2083V11.1806C5.83333 11.6389 5.83333 11.868 5.97571 12.0104C6.11809 12.1528 6.34724 12.1528 6.80555 12.1528C7.26386 12.1528 7.49302 12.1528 7.6354 12.0104C7.77778 11.868 7.77778 11.6389 7.77778 11.1806Z"
      :stroke="color" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M10.2083 14.5833L10.2083 13.125" :stroke="color" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M13.6111 10.6944L13.6111 9.72222" :stroke="color" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M10.2083 7.29167L10.2083 5.83334" :stroke="color" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M13.6111 6.80556L13.6111 5.83334" :stroke="color" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M6.80555 13.125L6.80555 12.1528" :stroke="color" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M6.80555 9.23612L6.80555 8.26389" :stroke="color" stroke-linecap="round" stroke-linejoin="round" />
  </svg>


</template>
<script>
export default {
  name: "darvas_tool",
  props: {
    color: {
      type: String,
      default: "#000000"
    }
  }
}
</script>