export const tourRef = {
    TOUR_REF: 'appTour'
}
export const tourConst = {
    RIGHT: 'right',
    LEFT: 'left',
    TOP: 'top',
    BOTTOM: 'bottom',
}

export const activityAccordion = {
    EMPTY_TAB: 'emptyTab',
    ONE_TAB: 'oneTab',
    TWO_TAB: 'twoTab',
    THREE_TAB: 'threeTab',
}

export const dynamicSteps = {
    SCAN_STEP: 'scan_step',
    TRADING_STEP: 'trading_step',
    INDICATOR_STEP: 'indicator_step',
    EXCHANGE_STEP: 'exchange_step',
}
export const stepContent = {
    WELCOME: `Welcome to Protrader  Let's get started.`,
    DRAWING_TOOLS: `Here you can find all your  drawing tools.`,
    CHART_TIME: `Here is where you can change the chart time period.`,
    CHART_RIGHT_DATA: `Here is where all your  market data is kept.`,
    DROPDOWN_SELECT: `Select a button to continue.`,
    SCAN_SELECTION: `Let’s do our first scan! Click the 'Scan' button to continue.`,
    SCAN_GURU: `Click here to open all of our Guru Scans.`,
    SCAN_STANDARD: `Click here to open our standard scans.`,
    SCAN_ACTIVITY: `Let’s do an activity scan. Click Activity.`,
    SCAN_ACTIVITY_MENU: `You can select your scan by clicking on “Activity”.`,
    SCAN_ACTIVITY_SETTING: `Let’s do a top 10 scan to find  stocks with the highest volume! `,
    SCAN_ACTIVITY_TOP_VALUE: `Make sure that the Top 10 number is set to 10.`,
    SCAN_ACTIVITY_VOLUME: `Make sure the drop down is set to "Volume"<br/> You can also do a scan with different parameters, let's do that later`,
    SCAN_ACTIVITY_FILTER: `Let’s add some filters to  ensure we don’t get junk results`,
    SCAN_ACTIVITY_PRICE_RANGE: `Tick the checkbox <br/> Choose the price range from 1 to 30 - This will ensure we are searching for stocks in this price range`,
    SCAN_ACTIVITY_IGNORE: `Tick the checkbox <br/> Input 250 - This will ensure we are ignoring IPO's and stocks with low trading data`,
    SCAN_ACTIVITY_ONLY_RES: `Tick the checkbox <br/> Input 20 - This will ensure we are only scanning stocks with recent trading data`,
    SCAN_ACTIVITY_SCANTYPE: `Lets choose the market we want to scan`,
    SCAN_ACTIVITY_SCANTYPE_TIME: `Let's keep it on Daily`,
    SCAN_ACTIVITY_SCANTYPE_EXCHANGE: `Let's filter to the ASX Only! Select the drop down and change it to ASX`,
    SCAN_ACTIVITY_SCANTYPE_INDUSTRY: `Let's keep the industries as "ALL"`,
    SCAN_ACTIVITY_SCANBTN: `Now it’s time to Scan! Pro Trader scans the entire market in seconds!`,
    SCAN_RES: `Here are your Scan Results!`,
    SCAN_RES_AUTOPLAY: `You can play through them automatically`,
    SCAN_RES_WATCHLIST_CREATE: `You can create a new watchlist with the results`,
    WATCHLIST: `Your watchlists will appear here`,
    WATCHLIST_1: `You can do as many scans as you like`,
    TRADING_ROOM: `Don’t forget to enjoy our exclusive trading room`,
    CHART_TYPE: `You can change your chart type here`,
    CHART_SPLIT: `You can enjoy our Split view`,
    INDICATOR_SELECTION: `Let's have a quick look at our Indicators. Click the Indicators button.`,
    INDICATOR_INFO: `Pro Trader has over 30 different indicators that you can put on all at the same time.`,
    INDICATOR_TREND: `Let's open the Trend Indicators`,
    INDICATOR_MACD: `Let's select MACD`,
    INDICATOR_MACD_SETTINGS: `As soon as you select the indicator, you'll see your settings pop up`,
    INDICATOR_CONFIRM: `Click Confirm to apply your indicator`,
    SYMBOL_SEARCH: `To search for a stock just type the code here`,
    SYMBOL_SEARCH_EXCHANGE: `To access a different exchange, or to search for a stock from our list, click here`,
    USER_MENU: `Here is where all your settings and user information is - Click here to have a look`,
    USER_MENU_SETTINGS: `In here you can completely customise your software`,
    USER_MENU_FRIDAY: `Here you can access our Friday broadcast by Frank Watkins - New every Friday`,
    USER_MENU_SUPPORT: `If you need anything, contact support`,
    TOUR_COMPLETE: `Enjoy the platform! Reach out if you need help!`,
}