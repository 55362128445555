import { render, staticRenderFns } from "./BlockWithLoader.vue?vue&type=template&id=101af4e3&scoped=true"
import script from "./BlockWithLoader.vue?vue&type=script&lang=js"
export * from "./BlockWithLoader.vue?vue&type=script&lang=js"
import style0 from "./BlockWithLoader.vue?vue&type=style&index=0&id=101af4e3&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "101af4e3",
  null
  
)

export default component.exports