export const fibonacciLines = [
  { enable: true, val: 0.236, color: 'rgb(242, 54, 69, 1)' },
  { enable: true, val: 0.382, color: 'rgb(255, 152, 0, 1)' },
  { enable: true, val: 0.5, color: 'rgba(76, 175, 80, 1)' },
  { enable: true, val: 0.618, color: 'rgba(8, 153, 129, 1)' },
  { enable: true, val: 0.786, color: 'rgba(0, 188, 212, 1)' },
  { enable: true, val: 1, color: 'rgba(120, 123, 134, 1)' },
  { enable: true, val: 1.382, color: 'rgba(41, 98, 255, 1)' },
  { enable: true, val: 1.618, color: 'rgba(242, 54, 69, 1)' },
  { enable: true, val: 2, color: 'rgba(156, 39, 176, 1)' },
  { enable: true, val: 2.382, color: 'rgba(233, 30, 99, 1)' },
  { enable: true, val: 2.618, color: 'rgba(255, 152, 0, 1)' },
]