<template>
  <svg  viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.28667 10L7.05417 11.7683M7.64334 7.64334L9.41084 9.41M10 5.28667L11.7683 7.055M4.225 11.0608L11.06 4.225C11.72 3.565 12.05 3.235 12.4308 3.11167C12.7656 3.00292 13.1261 3.00292 13.4608 3.11167C13.8408 3.235 14.1708 3.565 14.83 4.22417L15.775 5.16667C16.435 5.82667 16.765 6.15834 16.8892 6.53834C16.9975 6.87334 16.9975 7.23334 16.8892 7.5675C16.765 7.94834 16.435 8.27834 15.775 8.93834L8.94 15.7742C8.28 16.4342 7.94917 16.7642 7.56917 16.8875C7.23417 16.9958 6.87417 16.9958 6.54 16.8875C6.15917 16.7642 5.82834 16.4342 5.16834 15.7742L4.22417 14.83C3.56584 14.1708 3.235 13.8408 3.11167 13.4608C3.00292 13.1261 3.00292 12.7656 3.11167 12.4308C3.235 12.05 3.56584 11.72 4.22584 11.06"
      :stroke="color" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
  </svg>


</template>
<script>
export default {
  name: "ruler_tool",
  props: {
    color: {
      type: String,
      default: "#000000"
    }
  }
}
</script>