<template>
  <svg  viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.66666 17.9167H18.3333M15 12.9167H15.0075M15 15.4167H15.0075M5 15.4167H5.0075M5 12.9167H5.0075"
      :stroke="color" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M7.33333 9.29168C7.33333 10.5113 6.34463 11.5 5.125 11.5C3.90537 11.5 2.91666 10.5113 2.91666 9.29168C2.91666 8.07205 3.90537 7.08334 5.125 7.08334C6.34463 7.08334 7.33333 8.07205 7.33333 9.29168ZM5.125 10.25C5.65427 10.25 6.08333 9.82095 6.08333 9.29168C6.08333 8.7624 5.65427 8.33334 5.125 8.33334C4.59572 8.33334 4.16666 8.7624 4.16666 9.29168C4.16666 9.82095 4.59572 10.25 5.125 10.25Z"
      :fill="color" />
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M17.3333 9.29168C17.3333 10.5113 16.3446 11.5 15.125 11.5C13.9054 11.5 12.9167 10.5113 12.9167 9.29168C12.9167 8.07205 13.9054 7.08334 15.125 7.08334C16.3446 7.08334 17.3333 8.07205 17.3333 9.29168ZM15.125 10.25C15.6543 10.25 16.0833 9.82095 16.0833 9.29168C16.0833 8.7624 15.6543 8.33334 15.125 8.33334C14.5957 8.33334 14.1667 8.7624 14.1667 9.29168C14.1667 9.82095 14.5957 10.25 15.125 10.25Z"
      :fill="color" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.75 9.79169H6.66666V8.54169H13.75V9.79169Z" :fill="color" />
  </svg>


</template>
<script>
export default {
  name: "horizontal_line_tool",
  props: {
    color: {
      type: String,
      default: "#000000"
    }
  }
}
</script>