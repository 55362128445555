<template>
  <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M5.16666 15.7083C5.16666 16.3297 4.66298 16.8333 4.04166 16.8333C3.42034 16.8333 2.91666 16.3297 2.91666 15.7083C2.91666 15.087 3.42034 14.5833 4.04166 14.5833C4.66298 14.5833 5.16666 15.087 5.16666 15.7083ZM6.29581 16.4583C5.98183 17.4025 5.09126 18.0833 4.04166 18.0833C2.72999 18.0833 1.66666 17.02 1.66666 15.7083C1.66666 14.3967 2.72999 13.3333 4.04166 13.3333C5.18182 13.3333 6.13433 14.1368 6.36394 15.2083H17.5C17.8452 15.2083 18.125 15.4882 18.125 15.8333C18.125 16.1785 17.8452 16.4583 17.5 16.4583H6.29581Z"
      :fill="color" />
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M16.8333 4.04166C16.8333 4.66298 16.3297 5.16666 15.7083 5.16666C15.087 5.16666 14.5833 4.66298 14.5833 4.04166C14.5833 3.42034 15.087 2.91666 15.7083 2.91666C16.3297 2.91666 16.8333 3.42034 16.8333 4.04166ZM18.0833 4.04166C18.0833 5.35333 17.02 6.41666 15.7083 6.41666C14.6587 6.41666 13.7682 5.7358 13.4542 4.79166H2.29166C1.94649 4.79166 1.66666 4.51183 1.66666 4.16666C1.66666 3.82148 1.94649 3.54166 2.29166 3.54166H13.3861C13.6157 2.47007 14.5682 1.66666 15.7083 1.66666C17.02 1.66666 18.0833 2.72998 18.0833 4.04166Z"
      :fill="color" />
  </svg>


</template>
<script>
export default {
  name: "parallel_line_tool",
  props: {
    color: {
      type: String,
      default: "#000000"
    }
  }
}
</script>