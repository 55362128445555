import { render, staticRenderFns } from "./HealthBarModal.vue?vue&type=template&id=2d709338&scoped=true"
import script from "./HealthBarModal.vue?vue&type=script&lang=js"
export * from "./HealthBarModal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2d709338",
  null
  
)

export default component.exports