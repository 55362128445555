export const marketsData = [

  {
    id: 1,
    selected: false,
    hide: false,
    desc: 'ASX',
    code: 'ASX',
    filterOrder: 1,
    marketTime: {
      zone: 'Australia/Sydney',
      startTime: 9,
      endTime: 16,
    },
  },
  {
    id: 3,
    selected: false,
    hide: false,
    desc: 'INDICES',
    code: 'INDX',
    marketTime: {
      zone: 'America/New_York',
      startTime: 9,
      endTime: 17,
    },
  },
  {
    id: 4,
    selected: false,
    hide: false,
    desc: 'NASDAQ',
    code: 'NASDAQ',
    filterOrder: 2,
    marketTime: {
      zone: 'America/New_York',
      startTime: 9,
      endTime: 17,
    },
  },
  {
    id: 5,
    selected: false,
    hide: false,
    desc: 'NYSE',
    code: 'NYSE',
    filterOrder: 3,
    marketTime: {
      zone: 'America/New_York',
      startTime: 9,
      endTime: 17,
    },
  },
  {
    id: 6,
    selected: false,
    hide: false,
    desc: 'SGX',
    code: 'SG',
    marketTime: {
      zone: 'Asia/Singapore',
      startTime: 9,
      endTime: 17,
    },
  },
  {
    id: 7,
    selected: false,
    hide: false,
    desc: 'NYSE ARCA',
    code: 'NYSE ARCA',
    filterOrder: 6,
    marketTime: {
      zone: 'America/New_York',
      startTime: 9,
      endTime: 17,
    },
  },
  {
    id: 8,
    selected: false,
    hide: false,
    desc: 'CBOE BZX',
    code: 'CBOE BZX',
    filterOrder: 7,
    marketTime: {
      zone: 'America/New_York',
      startTime: 9,
      endTime: 17,
    },
  },
  {
    id: 9,
    selected: false,
    hide: false,
    desc: 'NYSE AMERICAN',
    code: 'NYSE AMERICAN',
    marketTime: {
      zone: 'America/New_York',
      startTime: 9,
      endTime: 17,
    },
  },
  {
    id: 16,
    selected: false,
    hide: false,
    desc: 'TSX VENTURE NEX',
    code: 'TSX VENTURE NEX',
    marketTime: {
      zone: 'America/New_York',
      startTime: 9,
      endTime: 17,
    },
  },
  {
    id: 17,
    selected: false,
    hide: false,
    desc: 'TSX VENTURE',
    code: 'TSX VENTURE',
    filterOrder: 5,
    marketTime: {
      zone: 'America/New_York',
      startTime: 9,
      endTime: 17,
    },
  },
  {
    id: 18,
    selected: false,
    hide: false,
    desc: 'TSX',
    code: 'TSX',
    filterOrder: 4,
    marketTime: {
      zone: 'America/New_York',
      startTime: 9,
      endTime: 17,
    },
  },
  {
    id: 19,
    selected: false,
    hide: false,
    desc: 'NEO',
    code: 'NEO',
    marketTime: {
      zone: 'America/New_York',
      startTime: 9,
      endTime: 17,
    },
  },
  {
    id: 20,
    selected: false,
    hide: false,
    desc: 'CSE',
    code: 'CSE',
    filterOrder: 8,
    marketTime: {
      zone: 'America/New_York',
      startTime: 9,
      endTime: 17,
    },
  },
  {
    id: 10,
    selected: false,
    hide: false,
    desc: 'FX',
    code: 'FX',
    filterOrder: 9,
    marketTime: {
      zone: 'America/New_York',
      startTime: 9,
      endTime: 17,
    },
  },
  {
    id: 11,
    selected: false,
    hide: false,
    desc: 'CME',
    code: 'CME',
    marketTime: {
      zone: 'America/New_York',
      startTime: 9,
      endTime: 17,
    },
  },
  {
    id: 12,
    selected: false,
    hide: false,
    desc: 'ME',
    code: 'ME',
    marketTime: {
      zone: 'America/New_York',
      startTime: 9,
      endTime: 17,
    },
  },
  {
    id: 13,
    selected: false,
    hide: false,
    desc: 'LME',
    code: 'LME',
    marketTime: {
      zone: 'America/New_York',
      startTime: 9,
      endTime: 17,
    },
  },
  {
    id: 14,
    selected: false,
    hide: false,
    desc: 'LPPM',
    code: 'LPPM',
    marketTime: {
      zone: 'America/New_York',
      startTime: 9,
      endTime: 17,
    },
  },
  {
    id: 15,
    selected: false,
    hide: false,
    desc: 'Non Exchange',
    code: 'NO EXCHANGE',
    marketTime: {
      zone: 'America/New_York',
      startTime: 9,
      endTime: 17,
    },
  },
]
