<template>
    <span class="p-mr-3">
        <p-button class="p-button-light p-button-text"
            :disabled="disablePlayBackward" @click="startPlay(false)"
            style="padding: 0.5rem;">
            <span :class="`${!isAutoPlay ? 'custom-btn-primary' : 'custom-btn-secondary'} play-backward-btn`"></span>
        </p-button>
        <p-button class="p-button-light p-button-text"
            :disabled="disablePlayForward" @click="startPlay(true)"
            style="padding: 0.5rem;">
            <span :class="`${!isAutoPlay ? 'custom-btn-primary' : 'custom-btn-secondary'} play-forward-btn`"></span>
        </p-button>
        <p-button class="p-button-light p-button-text" :disabled="disablePause"
            @click="stopPlay" style="padding: 0.5rem;">
            <span :class="`${isAutoPlay ? 'custom-btn-primary' : 'custom-btn-secondary'} pause-btn`"></span>
        </p-button>
    </span>
</template>

<script>
export default {
    name: "AutoPlay",
    props: {
        disablePlayForward: {
            type: Boolean,
            default: false
        },
        disablePlayBackward: {
            type: Boolean,
            default: false
        },
        disablePause: {
            type: Boolean,
            default: false
        },
        isAutoPlay: {
            type: Boolean,
            default: false
        }
    }, 
    methods:{
        startPlay(isForward){
            this.$emit('startAutoPlay', isForward)
        },
        stopPlay(){
            this.$emit('stopAutoPlay')
        },
        handleKeyDown(event){
            if (event.code == 'Space' && this.isAutoPlay) {
                this.stopPlay()
            }
        }
    },
    mounted(){
        window.addEventListener('keydown',this.handleKeyDown)
    },
    beforeDestroy(){
        window.removeEventListener('keydown', this.handleKeyDown)
    }
}
</script>

<style lang="scss" scoped></style>