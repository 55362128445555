<template>

<svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 30 28" fill="none">
  <path d="M7 14H21M21 14L16 10M21 14L16 18" stroke="currentColor"/>
</svg>

</template>

<script>
export default {
  name: 'DottedLine'
}
</script>

<style>
</style>