<template>
  <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_467_6845)">
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M7.20043 6.0569C8.39894 4.92626 9.65145 4.41337 10.8473 4.41266C12.0771 4.41193 13.3823 4.95274 14.6432 6.15301C14.0802 6.53293 13.711 7.1701 13.711 7.89205C13.711 9.05624 14.6711 10 15.8555 10C17.0399 10 18 9.05624 18 7.89205C18 6.88724 17.2848 6.04664 16.3274 5.83531C14.7049 4.0025 12.8152 2.99884 10.8464 3C8.84257 3.00119 6.94291 4.04313 5.34492 5.93549C4.55657 6.24707 4 7.00567 4 7.89205C4 9.05624 4.96013 10 6.14451 10C7.32889 10 8.28902 9.05624 8.28902 7.89205C8.28902 7.1052 7.85042 6.41904 7.20043 6.0569ZM6.14451 8.80682C6.65848 8.80682 7.07514 8.39726 7.07514 7.89205C7.07514 7.38683 6.65848 6.97727 6.14451 6.97727C5.63053 6.97727 5.21387 7.38683 5.21387 7.89205C5.21387 8.39726 5.63053 8.80682 6.14451 8.80682ZM15.8555 8.80682C16.3695 8.80682 16.7861 8.39726 16.7861 7.89205C16.7861 7.38683 16.3695 6.97727 15.8555 6.97727C15.3415 6.97727 14.9249 7.38683 14.9249 7.89205C14.9249 8.39726 15.3415 8.80682 15.8555 8.80682Z"
        :fill="color" />
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M2 17.75C2 17.3358 2.33579 17 2.75 17L19.5 17C19.9142 17 20.25 17.3358 20.25 17.75C20.25 18.1642 19.9142 18.5 19.5 18.5L2.75 18.5C2.33579 18.5 2 18.1642 2 17.75Z"
        :fill="color" />
      <path
        d="M15.491 11.75C15.491 11.3358 15.8268 11 16.241 11H16.25C16.6642 11 17 11.3358 17 11.75C17 12.1642 16.6642 12.5 16.25 12.5H16.241C15.8268 12.5 15.491 12.1642 15.491 11.75Z"
        :fill="color" />
      <path
        d="M15.491 14.75C15.491 14.3358 15.8268 14 16.241 14H16.25C16.6642 14 17 14.3358 17 14.75C17 15.1642 16.6642 15.5 16.25 15.5H16.241C15.8268 15.5 15.491 15.1642 15.491 14.75Z"
        :fill="color" />
      <path
        d="M5.48049 11.75C5.48049 11.3358 5.81628 11 6.23049 11H6.23949C6.65371 11 6.98949 11.3358 6.98949 11.75C6.98949 12.1642 6.65371 12.5 6.23949 12.5H6.23049C5.81628 12.5 5.48049 12.1642 5.48049 11.75Z"
        :fill="color" />
      <path
        d="M5.48049 14.75C5.48049 14.3358 5.81628 14 6.23049 14H6.23949C6.65371 14 6.98949 14.3358 6.98949 14.75C6.98949 15.1642 6.65371 15.5 6.23949 15.5H6.23049C5.81628 15.5 5.48049 15.1642 5.48049 14.75Z"
        :fill="color" />
    </g>
    <defs>
      <clipPath id="clip0_467_6845">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>


</template>
<script>
export default {
  name: "arc_tool",
  props: {
    color: {
      type: String,
      default: "#000000"
    }
  }
}
</script>