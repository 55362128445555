import { render, staticRenderFns } from "./AutoPlay.vue?vue&type=template&id=3ffbf0a5&scoped=true"
import script from "./AutoPlay.vue?vue&type=script&lang=js"
export * from "./AutoPlay.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ffbf0a5",
  null
  
)

export default component.exports