<template>
    <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="24" height="24" rx="12" :fill="fillColor" class="userSvgFill"/>
        <path
            d="M17.3333 18V16.6667C17.3333 15.9594 17.0523 15.2811 16.5522 14.781C16.0521 14.281 15.3739 14 14.6666 14H9.33329C8.62605 14 7.94777 14.281 7.44767 14.781C6.94758 15.2811 6.66663 15.9594 6.66663 16.6667V18M14.6666 8.66667C14.6666 10.1394 13.4727 11.3333 12 11.3333C10.5272 11.3333 9.33329 10.1394 9.33329 8.66667C9.33329 7.19391 10.5272 6 12 6C13.4727 6 14.6666 7.19391 14.6666 8.66667Z"
            :stroke="strokeColor" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round" class="userSvgStroke"/>
    </svg>

</template>

<script>

export default {
    name: 'UserCircleSvg',
    props: {
        strokeColor: {
            type: String,
            default: '#FDFDFD'
        },
        fillColor: {
            type: String,
            default: '#414651'
        },

    }
}
</script>

<style lang="scss" scoped></style>