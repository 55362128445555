export const actionsubType = {
    DRAWING_UPDATE: 0,
    DRAWING_ADD: 1,
    DRAWING_REMOVE: 2,
    DRAWING_REMOVE_ALL: 3,
    DRAWING_PARAM_UPDATE: 4,
    INDICATOR_UPDATE: 5,
}
export const actionType = {
    DRAWING_ACTION: 0,
    INDICATOR_ACTION: 0,
}
export const indicatorParam = {
    MULTI_VALUE_INDICATOR: 0,
    SINGLE_INDICATOR: 1,
    ADDITIONAL_INDICATOR: 2,
}