<template>
  <svg  viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M11.0096 4.65953C10.7294 4.87313 10.3795 4.99997 10 4.99997C9.62054 4.99997 9.27067 4.87314 8.99051 4.65955L7.30364 10.8447C7.36805 10.8372 7.43358 10.8333 7.50002 10.8333C7.87952 10.8333 8.2294 10.9601 8.50957 11.1737L10 5.70866L11.4905 11.1737C11.7707 10.9601 12.1205 10.8333 12.5 10.8333C12.5665 10.8333 12.632 10.8372 12.6964 10.8448L11.0096 4.65953ZM12.7592 10.8533C13.1957 10.9215 13.576 11.159 13.8309 11.4966L14.7042 10.0411L15.1127 12.0833H14.1667C13.9366 12.0833 13.75 12.2699 13.75 12.5C13.75 12.7301 13.9366 12.9167 14.1667 12.9167H15.2793L15.7508 15.274C16.0136 15.1008 16.3284 15 16.6667 15C16.7726 15 16.8761 15.0098 16.9765 15.0287L16.5541 12.9167H17.5C17.7301 12.9167 17.9167 12.7301 17.9167 12.5C17.9167 12.2699 17.7301 12.0833 17.5 12.0833H16.3874L15.9159 9.72595C15.6531 9.89916 15.3383 9.99996 15 9.99996C14.4563 9.99996 13.9734 9.73958 13.6691 9.3367L12.7592 10.8533ZM6.33093 9.33667L7.24092 10.8533C6.80442 10.9215 6.42404 11.159 6.16913 11.4966L5.29586 10.0411L4.88741 12.0833H5.83334C6.06345 12.0833 6.25 12.2699 6.25 12.5C6.25 12.7301 6.06345 12.9167 5.83334 12.9167H4.72074L4.24928 15.274C3.98645 15.1008 3.67167 15 3.33335 15C3.2275 15 3.12396 15.0098 3.02358 15.0287L3.44599 12.9167H2.5C2.26988 12.9167 2.08334 12.7301 2.08334 12.5C2.08334 12.2699 2.26988 12.0833 2.5 12.0833H3.61265L4.08413 9.72597C4.34695 9.89917 4.66172 9.99996 5.00002 9.99996C5.54378 9.99996 6.02671 9.73957 6.33093 9.33667ZM8.75 12.5C8.75 12.2699 8.93655 12.0833 9.16667 12.0833H10.8333C11.0635 12.0833 11.25 12.2699 11.25 12.5C11.25 12.7301 11.0635 12.9167 10.8333 12.9167H9.16667C8.93655 12.9167 8.75 12.7301 8.75 12.5Z"
      :fill="color" />
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M3.33333 17.5C3.79357 17.5 4.16666 17.1269 4.16666 16.6667C4.16666 16.2064 3.79357 15.8333 3.33333 15.8333C2.87309 15.8333 2.5 16.2064 2.5 16.6667C2.5 17.1269 2.87309 17.5 3.33333 17.5ZM3.33333 18.3333C4.25381 18.3333 5 17.5871 5 16.6667C5 15.7462 4.25381 15 3.33333 15C2.41286 15 1.66666 15.7462 1.66666 16.6667C1.66666 17.5871 2.41286 18.3333 3.33333 18.3333Z"
      :fill="color" />
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M5 9.16667C5.46024 9.16667 5.83334 8.79358 5.83334 8.33334C5.83334 7.8731 5.46024 7.50001 5 7.50001C4.53977 7.50001 4.16667 7.8731 4.16667 8.33334C4.16667 8.79358 4.53977 9.16667 5 9.16667ZM5 10C5.92048 10 6.66667 9.25381 6.66667 8.33334C6.66667 7.41286 5.92048 6.66667 5 6.66667C4.07953 6.66667 3.33334 7.41286 3.33334 8.33334C3.33334 9.25381 4.07953 10 5 10Z"
      :fill="color" />
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M15 9.16667C15.4602 9.16667 15.8333 8.79358 15.8333 8.33334C15.8333 7.8731 15.4602 7.50001 15 7.50001C14.5398 7.50001 14.1667 7.8731 14.1667 8.33334C14.1667 8.79358 14.5398 9.16667 15 9.16667ZM15 10C15.9205 10 16.6667 9.25381 16.6667 8.33334C16.6667 7.41286 15.9205 6.66667 15 6.66667C14.0795 6.66667 13.3333 7.41286 13.3333 8.33334C13.3333 9.25381 14.0795 10 15 10Z"
      :fill="color" />
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M10 4.16667C10.4602 4.16667 10.8333 3.79358 10.8333 3.33334C10.8333 2.8731 10.4602 2.50001 10 2.50001C9.53976 2.50001 9.16667 2.8731 9.16667 3.33334C9.16667 3.79358 9.53976 4.16667 10 4.16667ZM10 5.00001C10.9205 5.00001 11.6667 4.25381 11.6667 3.33334C11.6667 2.41286 10.9205 1.66667 10 1.66667C9.07952 1.66667 8.33333 2.41286 8.33333 3.33334C8.33333 4.25381 9.07952 5.00001 10 5.00001Z"
      :fill="color" />
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M7.5 13.3333C7.96024 13.3333 8.33333 12.9602 8.33333 12.5C8.33333 12.0398 7.96024 11.6667 7.5 11.6667C7.03976 11.6667 6.66667 12.0398 6.66667 12.5C6.66667 12.9602 7.03976 13.3333 7.5 13.3333ZM7.5 14.1667C8.42047 14.1667 9.16667 13.4205 9.16667 12.5C9.16667 11.5795 8.42047 10.8333 7.5 10.8333C6.57952 10.8333 5.83333 11.5795 5.83333 12.5C5.83333 13.4205 6.57952 14.1667 7.5 14.1667Z"
      :fill="color" />
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M12.5 13.3333C12.9602 13.3333 13.3333 12.9602 13.3333 12.5C13.3333 12.0398 12.9602 11.6667 12.5 11.6667C12.0398 11.6667 11.6667 12.0398 11.6667 12.5C11.6667 12.9602 12.0398 13.3333 12.5 13.3333ZM12.5 14.1667C13.4205 14.1667 14.1667 13.4205 14.1667 12.5C14.1667 11.5795 13.4205 10.8333 12.5 10.8333C11.5795 10.8333 10.8333 11.5795 10.8333 12.5C10.8333 13.4205 11.5795 14.1667 12.5 14.1667Z"
      :fill="color" />
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M16.6667 17.5C17.1269 17.5 17.5 17.1269 17.5 16.6667C17.5 16.2064 17.1269 15.8333 16.6667 15.8333C16.2064 15.8333 15.8333 16.2064 15.8333 16.6667C15.8333 17.1269 16.2064 17.5 16.6667 17.5ZM16.6667 18.3333C17.5871 18.3333 18.3333 17.5871 18.3333 16.6667C18.3333 15.7462 17.5871 15 16.6667 15C15.7462 15 15 15.7462 15 16.6667C15 17.5871 15.7462 18.3333 16.6667 18.3333Z"
      :fill="color" />
  </svg>


</template>
<script>
export default {
  name: "head_shoulder_tool",
  props: {
    color: {
      type: String,
      default: "#000000"
    }
  }
}
</script>