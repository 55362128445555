<template>
  <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.3333 17.9167H1.66667M5 12.9167H4.9925M5 15.4167H4.9925M15 15.4167H14.9925M15 12.9167H14.9925M15 10.4167H14.9925M15 7.91666H14.9925M13.5083 4.49333L6.485 7.99166M13.3333 3.74999C13.3333 4.19202 13.5089 4.61595 13.8215 4.92851C14.1341 5.24107 14.558 5.41666 15 5.41666C15.442 5.41666 15.866 5.24107 16.1785 4.92851C16.4911 4.61595 16.6667 4.19202 16.6667 3.74999C16.6667 3.30797 16.4911 2.88404 16.1785 2.57148C15.866 2.25892 15.442 2.08333 15 2.08333C14.558 2.08333 14.1341 2.25892 13.8215 2.57148C13.5089 2.88404 13.3333 3.30797 13.3333 3.74999ZM3.33334 8.74999C3.33334 9.19202 3.50893 9.61595 3.82149 9.92851C4.13405 10.2411 4.55797 10.4167 5 10.4167C5.44203 10.4167 5.86595 10.2411 6.17851 9.92851C6.49107 9.61595 6.66667 9.19202 6.66667 8.74999C6.66667 8.30797 6.49107 7.88404 6.17851 7.57148C5.86595 7.25892 5.44203 7.08333 5 7.08333C4.55797 7.08333 4.13405 7.25892 3.82149 7.57148C3.50893 7.88404 3.33334 8.30797 3.33334 8.74999Z"
      :stroke="color" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
  </svg>


</template>
<script>
export default {
  name: "trend_line_tool",
  props: {
    color: {
      type: String,
      default: "#000000"
    }
  }
}
</script>