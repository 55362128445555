<template>
  <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M2.70834 3.95834C2.70834 3.61317 2.98816 3.33334 3.33334 3.33334L16.6667 3.33334C17.0118 3.33334 17.2917 3.61317 17.2917 3.95834C17.2917 4.30352 17.0118 4.58334 16.6667 4.58334L3.33334 4.58334C2.98816 4.58334 2.70834 4.30352 2.70834 3.95834Z"
      :fill="color" />
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M4.375 12.2917C4.375 11.9465 4.65482 11.6667 5 11.6667L6.14583 11.6667C6.49101 11.6667 6.77083 11.9465 6.77083 12.2917C6.77083 12.6368 6.49101 12.9167 6.14583 12.9167L5 12.9167C4.65482 12.9167 4.375 12.6368 4.375 12.2917ZM7.8125 12.2917C7.8125 11.9465 8.09232 11.6667 8.4375 11.6667L10.7292 11.6667C11.0743 11.6667 11.3542 11.9465 11.3542 12.2917C11.3542 12.6368 11.0743 12.9167 10.7292 12.9167L8.4375 12.9167C8.09232 12.9167 7.8125 12.6368 7.8125 12.2917ZM12.3958 12.2917C12.3958 11.9465 12.6757 11.6667 13.0208 11.6667L14.1667 11.6667C14.5118 11.6667 14.7917 11.9465 14.7917 12.2917C14.7917 12.6368 14.5118 12.9167 14.1667 12.9167L13.0208 12.9167C12.6757 12.9167 12.3958 12.6368 12.3958 12.2917Z"
      :fill="color" />
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M17.2058 3.64237C17.3804 3.94015 17.2805 4.32307 16.9827 4.49763L15.9758 5.08791C15.678 5.26247 15.2951 5.16258 15.1205 4.8648C14.946 4.56701 15.0459 4.1841 15.3436 4.00954L16.3506 3.41926C16.6484 3.24469 17.0313 3.34458 17.2058 3.64237ZM14.185 5.4132C14.3596 5.71099 14.2597 6.0939 13.9619 6.26846L11.948 7.44902C11.6502 7.62358 11.2673 7.52369 11.0928 7.22591C10.9182 6.92812 11.0181 6.54521 11.3159 6.37065L13.3298 5.19009C13.6275 5.01553 14.0105 5.11542 14.185 5.4132ZM10.1572 7.77431C10.3318 8.0721 10.2319 8.45501 9.93413 8.62958L7.92024 9.81013C7.62245 9.98469 7.23954 9.8848 7.06498 9.58702C6.89041 9.28924 6.9903 8.90632 7.28809 8.73176L9.30198 7.5512C9.59976 7.37664 9.98268 7.47653 10.1572 7.77431ZM6.12946 10.1354C6.30402 10.4332 6.20413 10.8161 5.90635 10.9907L4.8994 11.581C4.60162 11.7555 4.21871 11.6556 4.04414 11.3579C3.86958 11.0601 3.96947 10.6772 4.26725 10.5026L5.2742 9.91231C5.57198 9.73775 5.9549 9.83764 6.12946 10.1354Z"
      :fill="color" />
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M2.70834 17.2917C2.70834 16.9465 2.98816 16.6667 3.33334 16.6667L16.6667 16.6667C17.0118 16.6667 17.2917 16.9465 17.2917 17.2917C17.2917 17.6368 17.0118 17.9167 16.6667 17.9167L3.33334 17.9167C2.98816 17.9167 2.70834 17.6368 2.70834 17.2917Z"
      :fill="color" />
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M3.20834 13.2917C3.82966 13.2917 4.33334 12.788 4.33334 12.1667C4.33334 11.5453 3.82966 11.0417 3.20834 11.0417C2.58702 11.0417 2.08334 11.5453 2.08334 12.1667C2.08334 12.788 2.58702 13.2917 3.20834 13.2917ZM3.20834 14.5417C4.52001 14.5417 5.58334 13.4783 5.58334 12.1667C5.58334 10.855 4.52001 9.79166 3.20834 9.79166C1.89666 9.79166 0.833336 10.855 0.833336 12.1667C0.833336 13.4783 1.89666 14.5417 3.20834 14.5417Z"
      :fill="color" />
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M16.5417 13.2917C17.163 13.2917 17.6667 12.788 17.6667 12.1667C17.6667 11.5453 17.163 11.0417 16.5417 11.0417C15.9203 11.0417 15.4167 11.5453 15.4167 12.1667C15.4167 12.788 15.9203 13.2917 16.5417 13.2917ZM16.5417 14.5417C17.8533 14.5417 18.9167 13.4783 18.9167 12.1667C18.9167 10.855 17.8533 9.79166 16.5417 9.79166C15.23 9.79166 14.1667 10.855 14.1667 12.1667C14.1667 13.4783 15.23 14.5417 16.5417 14.5417Z"
      :fill="color" />
  </svg>


</template>
<script>
export default {
  name: "trade_planner_tool",
  props: {
    color: {
      type: String,
      default: "#000000"
    }
  }
}
</script>