export const fontSizeMenuOption = [
  {
    label: '8 px',
    fontSize: 8
  },
  {
    label: '10 px',
    fontSize: 10
  },
  {
    label: '11 px',
    fontSize: 11
  },
  {
    label: '12 px',
    fontSize: 12
  },
  {
    label: '14 px',
    fontSize: 14
  },
  {
    label: '16 px',
    fontSize: 16
  },
  {
    label: '18 px',
    fontSize: 18
  },
  {
    label: '20 px',
    fontSize: 20
  },
  {
    label: '22 px',
    fontSize: 22
  },
  {
    label: '24 px',
    fontSize: 24
  },
  {
    label: '28 px',
    fontSize: 28
  },
  {
    label: '32 px',
    fontSize: 32
  },
  {
    label: '36 px',
    fontSize: 36
  },
  {
    label: '40 px',
    fontSize: 40
  },
  {
    label: '50 px',
    fontSize: 50
  },


]

export const lineWidthMenuOption = [
  {
    label: '1 px',
    width: 1
  },
  {
    label: '2 px',
    width: 2
  },
  {
    label: '3 px',
    width: 3
  },
  {
    label: '4 px',
    width: 4
  },
  {
    label: '5 px',
    width: 5
  },


]

export const lineStyleMenuOption = [
  {
    label: 'Solid',
    class: 'solidLine',
    linestyle: 'solid'
  },
  {
    label: 'Dashed',
    class: 'dashedLine',
    linestyle: 'dashed'
  },
  {
    label: 'Dotted',
    class: 'dottedLine',
    linestyle: 'dotted'
  }


]
export const lineHeadStyleMenuOption = [
  {
    label: 'Normal',
    class: 'solidLine',
    lineHeadStyle: 'normal'
  },
  {
    label: 'Arrow',
    class: 'arrowLine',
    lineHeadStyle: 'arrow'
  },
]